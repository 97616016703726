body, html, #root {
    height: 100%;
}

.App {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 100%;
    background: #121212;
}

svg {
    height: 40vmin;
    pointer-events: none;
}
